import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: _ctx.locale
  }, {
    default: _withCtx(() => [_ctx.showPage == 1 ? (_openBlock(), _createBlock(_component_router_view, {
      key: 0,
      name: "m"
    })) : _createCommentVNode("", true), _ctx.showPage == 2 ? (_openBlock(), _createBlock(_component_router_view, {
      key: 1,
      name: "p"
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["locale"]);
}