import { ref } from 'vue'

/** WebSocket */
export class Ws {
    /** ws */
    private ws: WebSocket | null
    /** 连接状态 */
    public socketStatus: boolean
    /** 消息内容 */
    public message: any
    // 重新连接尝试的次数
    connectRetryCount = 0
    // 存储回调函数
    callBackMapping = {}


    constructor() {
        this.ws = null
        this.socketStatus = false
        this.message = ref()
    }

    /** 建立连接 */
    connect(url:string) {
        // 连接服务器
        if (!window.WebSocket) {
            return console.log('您的浏览器不支持WebSocket')
        }
        this.ws = new WebSocket(url)
        this.ws.onopen = this.onopen.bind(this)
        this.ws.onmessage = this.onmessage.bind(this)
        this.ws.onclose = this.onclose.bind(this)
        this.ws.onerror = this.onerror.bind(this)
    }

    /** 连接成功 */
    onopen() {
        this.socketStatus = true
        // 重置重新连接的次数
        this.connectRetryCount = 0
        console.log('websocket连接成功', this.ws)
    }

    /** 接收消息 */
    onmessage(e: { data: string }) {
        this.message.value = JSON.parse(e.data)
        // console.log('接收消息', JSON.parse(e.data))
    }

    /** 连接关闭 */
    onclose() {
        this.socketStatus = false
        this.connectRetryCount++
        // setTimeout(() => {
        //     this.connect()
        // }, 500 * this.connectRetryCount)
        console.log('websocket连接关闭')
    }

    /** 连接失败 */
    onerror(e: any) {
        this.socketStatus = false
        // console.log('websocket连接失败', e);
    }

    /** 发送消息 */
    send(message: any) {
        if (this.ws && this.socketStatus) {
            this.ws.send(JSON.stringify(message))
            // console.log('发送消息', message)
        }
    }


    // 回调函数的注册
    registerCallBack(callBack: any) {
        // console.log('回调函数的注册', callBack)
        this.callBackMapping = callBack
    }

    // 取消某一个回调函数
    unRegisterCallBack(callBack: any) {
        // console.log('取消某一个回调函数', callBack)
        this.callBackMapping = {}
    }
}
