import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'animate.css'
import '@/assets/front/front.css'
import '@/assets/iconfont/dmkIconFont.css'
import '@/assets/animation/animation.css'
import '@/assets/animation/animate.min.css'
import '@/assets/animation/animate.compat.css'
import * as echarts from "echarts"
import VueLazyLoad from 'vue-lazyload'
import 'vant/lib/index.css'
import '@/utils/flexible.ts'
import '@/style/index.scss'
import './config'
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-notification.css";
import "element-plus/theme-chalk/el-message-box.css";

import {
    Button,
    Field,
    CellGroup,
    Icon,
    Checkbox,
    CheckboxGroup,
    Divider,
    CountDown,
    Col,
    Row,
    Image,
    Cell,
    Tabbar,
    TabbarItem,
    NavBar,
    Collapse,
    CollapseItem,
    Switch,
    Tag,
    Toast,
    Search,
    Tab,
    Tabs,
    SwipeCell,
    PullRefresh,
    Sticky,
    IndexBar,
    IndexAnchor,
    Sidebar,
    SidebarItem,
    DropdownMenu,
    DropdownItem,
    Swipe,
    SwipeItem,
    Step,
    Steps,
    Card,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    Skeleton,
    NumberKeyboard,
    ContactCard,
    SubmitBar,
    CouponCell,
    CouponList,
    Popup,
    Radio,
    RadioGroup,
    AddressEdit,
    AddressList,
    Dialog,
    Badge,
    ShareSheet,
    Popover,
    Stepper,
    Uploader,
    Form,
} from 'vant';






const app = createApp(App)
app.use(store)
    .use(router)
    .use(VueLazyLoad, {
        // error: require('./assets/site/error.png'),
        // loading: require('./assets/site/load.gif')
    })
    .use(Button)
    .use(Field)
    .use(CellGroup)
    .use(Icon)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Divider)
    .use(CountDown)
    .use(Col)
    .use(Row)
    .use(Image)
    .use(Cell)
    .use(Tabbar)
    .use(TabbarItem)
    .use(NavBar)
    .use(Collapse)
    .use(CollapseItem)
    .use(Switch)
    .use(Toast)
    .use(Tag)
    .use(Search)
    .use(Tab)
    .use(Tabs)
    .use(SwipeCell)
    .use(PullRefresh)
    .use(Sticky)
    .use(IndexBar)
    .use(IndexAnchor)
    .use(Sidebar)
    .use(SidebarItem)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(Step)
    .use(Steps)
    .use(Card)
    .use(ActionBar)
    .use(ActionBarIcon)
    .use(ActionBarButton)
    .use(Skeleton)
    .use(NumberKeyboard)
    .use(ContactCard)
    .use(SubmitBar)
    .use(CouponCell)
    .use(CouponList)
    .use(Popup)
    .use(Radio)
    .use(RadioGroup)
    .use(AddressEdit)
    .use(AddressList)
    .use(Dialog)
    .use(Badge)
    .use(ShareSheet)
    .use(Popover)
    .use(Stepper)
    .use(Uploader)
    .use(Form)
    .mount('#app')
app.config.globalProperties.$echarts = echarts

import { Ws } from '@/utils/Ws'
const ws = new Ws()
// 将ws挂载到vue全局
app.config.globalProperties.$ws = ws
// ws.connect('ws://192.168.0.170:9999/websocket')


ws.connect('ws://localhost:9999/websocket')





for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}