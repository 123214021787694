export default [
    {
      path: '/home',
      name: 'home',
      components: {
        default: () => import(/* webpackChunkName: "about" */ '@/views/pc/Home.vue'),
        p: () => import(/* webpackChunkName: "about" */ '@/views/pc/Home.vue'),
        m: () => import(/* webpackChunkName: "about" */ '@/views/mobile/Home.vue'),
      }
      },
]