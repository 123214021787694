import { defineComponent, onMounted, ref, onUnmounted, onBeforeMount, onBeforeUpdate, onUpdated, onBeforeUnmount, onActivated, onDeactivated, onErrorCaptured } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import WOW from "wow.js";
export default defineComponent({
  components: {
    ElConfigProvider
  },
  setup() {
    const showPage = ref(1);
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    const remSize = () => {
      let deviceWidth = document.documentElement.clientWidth || window.innerWidth; //兼容多浏览器
      // if (isMobile() && deviceWidth < 1000) {
      //   showPage.value = 1;
      // } else {
      //   showPage.value = 2
      // }
      if (isMobile() && deviceWidth < 600) {
        showPage.value = 1;
      } else {
        showPage.value = 2;
      }
    };
    let deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    if (deviceWidth < 600) {
      showPage.value = 1;
    } else {
      showPage.value = 2;
    }
    const isMobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    };
    function F12KeyDown() {
      let deviceWidth = document.body.clientWidth;
      if (deviceWidth < 600) {
        showPage.value = 1;
      } else {
        showPage.value = 2;
      }
    }
    onMounted(() => {
      window.addEventListener('resize', event => {
        remSize();
        F12KeyDown();
      });
      var wow = new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 200,
        mobile: true,
        live: true,
        scrollContainer: null,
        resetAnimation: true
      });
      wow.init();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', event => {
        remSize();
        F12KeyDown();
      });
    });
    return {
      locale: zhCn,
      showPage
    };
  }
});